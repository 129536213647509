.avatarUploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 2rem auto 0;
}

.avatarWrapper {
  position: relative;
}

.avatarImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #ddd;
}

.addIcon {
  position: absolute;
  bottom: 16px;
  right: 5px;
  width: 41px;
  height: 41px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.addIcon:hover {
  transform: scale(1.1);
}

/* Стили для кнопок */
.deleteButton, .cancelButton, .uploadButton {
  background: none;
  border: none;
  padding: 0;
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: gray;
  transition: color 0.3s ease;

}

.deleteButton:hover, .cancelButton:hover, .uploadButton:hover {
  color: black;
}

.deleteButton:disabled, .cancelButton:disabled, .uploadButton:disabled {
  color: gray;
  cursor: not-allowed;
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
}
