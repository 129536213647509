.adminNavbar{
  width: 100%;
  padding: 20px 0;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.linkContainer {
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-evenly;
    /* align-items: center; */
    width: 100%;
    column-gap: 10px;
    flex-wrap: wrap;

  }
  
  .navLink {
    color: gray;
    text-decoration: none;
    padding: 0px 10px;
    margin: 0 10px;
    /* font-size: 16px; */
    position: relative;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
  }
  
  .activeNavLink {
    color: black;
    border-bottom: 2px solid black;
  }
  @media (max-width: 990px) {
.linkContainer {
  justify-content: center;
  gap: 10px;
}
  }