.container {
    position: relative;
    max-width: 300px; 
    margin: 10px 0;
  }
  
  .select {
    width: 100%;
    padding: 10px 25px 10px 10px;
    border-radius: 5px;
    border: 1px solid gray;
    text-align: center;
    cursor: pointer;
  }
  
  .select.error {
    border-color: red;
  }
  
  .select.selected {
    background-color: #8DD24A;
  }
  .optionSelect {
    padding: 10px;
  }
  
  .errorText {
    color: red;
    text-align: start;
    margin-left: 10px;
    padding-top: 0;
  }