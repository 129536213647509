.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-image: url('../assets/downloadModalBg.svg');
  background-size: cover;
  background-position: center;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  width: 331px;
  height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* justify-content: end; */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5em;
  cursor: pointer;
}

.storeLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* .storeLinks a {

} */

.storeLinks img {
  /* height: 50px; */
  width: 147.16px;
}
