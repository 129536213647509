.notFoundContainer {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
    font-size: 2rem;
    margin-bottom: 1rem;
    /* text-align: center; */
  }
  
  .description {
    font-size: 1rem;
  }
