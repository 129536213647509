/* .container {
   margin-top: 100px;
} */
.uploadForm {
  border: solid 1px #ADADAD;
  margin: 20px 0;
  flex-direction: row;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  max-width: 450px;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  min-width: 220px;
  }
  
 .videoContainer {
   flex-direction: column;
   display: flex;
   gap: 10px;
   padding: 20px 0;
   margin-bottom: 20px;
 }
  
  .uploadInput {
    margin-bottom: 10px;
  }
  
  .uploadButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .uploadButton:disabled {
    background-color: #ccc;
  }
  
  .progress {
    width: 100%;
    background-color: #ddd;
  }
  
  /* .videoContainer {
    margin-top: 20px;
  } */
   .deleteButton {
    /* display: block; */
    align-self: flex-start;
    background-color: #cc0b0b;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* margin-right: 0; */
   }
  
  .videoPlayer {
    width: 100%;
    height: 80vh;
    width: 240px;
    height: 420px;
    /* height: auto; */
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
  }
  
  .copyButton {
    background-color: #FF9800;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .copyMessage {
    text-align: start;
    padding: 0 0 0 5px;
  }
  .responseMessage {
    text-align: start;
    padding: 0 0 20px 5px;
  }
  
