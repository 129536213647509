
.buttonGroup {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}

.buttonGroup button {
    /* margin: 0 15px; */
}

.editButton {
    /* padding: 8px 15px; */
    padding: 10px 15px;
    background-color: #b0a544;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 142px;
    align-self: flex-start;
}

.editButton:hover {
    background-color: #948d3a;
}

.deleteButton {
    /* padding: 8px 15px; */
    padding: 10px 15px; 
    background-color: #ff6347;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 142px;
    align-self: flex-start;

}

.deleteButton:hover {
    background-color: #e53e30;
}

.deleteButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 99, 71, 0.5);
}

.statusText {
    border: none;
    cursor: pointer;
}

/* https://css-loaders.com/3d/#l9 */
.transforming {
    --s: 10px;
    --g: 1px;

    width: calc(2 * (1.353 * var(--s) + var(--g)));
    aspect-ratio: 1;
    background: linear-gradient(#ff1818 0 0) left/50% 100% no-repeat,
    conic-gradient(from -90deg at var(--s) calc(0.353 * var(--s)),
            #fff 135deg, #666 0 270deg, #aaa 0);
    background-blend-mode: multiply;
    --_m: linear-gradient(to bottom right,
    #0000 calc(0.25 * var(--s)), #000 0 calc(100% - calc(0.25 * var(--s)) - 1.414 * var(--g)), #0000 0),
    conic-gradient(from -90deg at right var(--g) bottom var(--g), #000 90deg, #0000 0);
    -webkit-mask: var(--_m);
    mask: var(--_m);
    background-size: 50% 50%;
    -webkit-mask-size: 50% 50%;
    mask-size: 50% 50%;
    -webkit-mask-composite: source-in;
    mask-composite: intersect;
    animation: l9 1.5s infinite;
}

.transforming:hover {
    background-color: #e53e30;
}

@keyframes l9 {
    0%, 12.5% {
        background-position: 0% 0%, 0 0
    }
    12.6%, 37.5% {
        background-position: 100% 0%, 0 0
    }
    37.6%, 62.5% {
        background-position: 100% 100%, 0 0
    }
    62.6%, 87.5% {
        background-position: 0% 100%, 0 0
    }
    87.6%, 100% {
        background-position: 0% 0%, 0 0
    }
}

/* https://css-loaders.com/factory/#l6 */
.uploader {
    width: 90px;
    height: 14px;
    box-shadow: 0 3px 0 #fff;
    position: relative;
    display: grid;
    clip-path: inset(-60px 0 -5px)
}
.uploader:after {
    content: "";
    position: relative;
    background: repeating-linear-gradient(90deg,#0000 0 calc(50% - 8px), #ccc 0 calc(50% + 8px), #0000 0 100%) 0 0/calc(100%/3) 100%;
    animation: l6-1 1s infinite;
}
.uploader:before {
    content: "";
    position: absolute;
    width: 14px;
    aspect-ratio: 1;
    left: calc(50% - 7px);
    bottom: 0;
    border-radius: 50%;
    background: lightblue;
    animation: l6-2 1s infinite;
}
@keyframes l6-1 {
    50%,100% {background-position: calc(100%/2) 0}
}
@keyframes l6-2 {
    0%,50% {transform:translateY(-80px)}
}

@media screen and (max-width: 780px) {
    .buttonGroup {
      flex-direction: row;
    }
  }