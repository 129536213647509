.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 9999; bug in the discountsList datagrid pagination select rows */
    z-index: 1300;
}

.modalContent {
    background-color: #fff;
    color: #333;
    padding: 30px;
    min-width: 350px;
    max-width: 800px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 90vh;
}

.closeButton {
    position: absolute;
    top: 5px;
    color: black;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    margin-top: 0;
}