.navbar {
  background-color: #f8f8f8;
  border-bottom: 2px solid #ddd;

}

.topRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 25px 0;
  column-gap: 10px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: 10px;
}

.user {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative; /* Added for dropdown positioning */
  text-align: end;
}

.user img {
  border-radius: 50%;
}

/* Dropdown Menu Styles */
.dropdownContent {
  display: none; 
  position: absolute;
  top: 99%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1; 
  right: 3%;
}

.dropdownContent a {
  color: black;
  padding: 10px 10px;

  text-decoration: none;
  display: block; 
}

.dropdownContent a:hover {
  background-color: #f1f1f1;
}

.user:hover .dropdownContent {
  display: block; 
}

.dropdownContent button {
  color: black;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  text-align: left; 
  width: 100%; 
  cursor: pointer;
}

.dropdownContent button:hover {
  background-color: #f1f1f1;
}
.logoutBtn {
  font-size: 1rem;
}

.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ddd;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .topRow {
    padding: 10px;
  }
  .user {
    gap: 0.5rem;
  }
  .logo img {
    margin-right: 10px;
    width: 75px;
  }

}