.listInput {
    display: block;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px);
    min-width: 220px;
  }
  
  .formSaveConfirmBtn {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  .categoryBtn {
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  
  .formSaveConfirmBtn:hover {
    background-color: #147617;;
  }
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    z-index: 1001;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    padding: 0;
  }
  .pointer {
    cursor: pointer;
  }