
  .modal-overlay-user {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content-user {
    background-color: #fff;
    color: #333;
    padding: 30px;
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
    position: relative;
    overflow: hidden;
}

.close-button-user {
    position: absolute;
    top: 5px;
    color: black;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    margin-top: 0;
}

h2 {
    margin-top: 0;
    color: #333;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.user-details-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.user-details-list li {
    padding: 8px 0;
    border-bottom: 1px solid #e5e5e5;
}

.user-details-list li:last-child {
    border-bottom: none;
}

.role-selection-container {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 5px 0;
    justify-content: flex-start;
    margin: 0 1rem;
    margin-left: 1rem;
    /* border-bottom: 1px solid #e5e5e5; */
}
