.videoListContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: start;
  width: 100%;
}
.paginationContainer nav {
  width: auto;
}
.videoListTitle {
  text-align: start;
  margin-bottom: 20px;
}

.videoList {
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 45vh;
}

.videoItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  margin-bottom: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: pointer;
  background-color: #fff;
  gap: 1.5rem;
}

.videoItem:hover {
  background-color: #eaeaea;

}

.videoThumbnail {
  width: 75px;
  height: 140px;
  object-fit: cover;
  border-radius: 4px;
  background-color: #e0e0e0;
}
.videoListInfoWrapper {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.videoInfo p {
  text-align: start;
  padding: 8px 0 0 0;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 27px;
  
}

.videoTitle {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.searchContainer {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding-left: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 300px;
  min-width:150px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;

}

.searchIcon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInput {
  border: none;
  background: transparent;
  outline: none;
  padding: 10px 5px;
  width: 100%;
}
.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.category {
  background-color: rgba(0, 0, 0, 0.15);
  margin-right: 5px;
  border-radius: 5px;
  padding: 0 5px;
}
.searchTabs {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.tab {
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 6px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: gray;
  font-size: 1rem;
  
}
.tab:hover {
  color: black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.active {
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #C373FF;
  color: black;
}
.searchTypeContainer {
  margin: 15px 0;
  display: grid;
  gap: 1rem;
}
@media screen and (max-width: 780px) {
  .videoListContainer {
    padding: 20px 0;
  }
  .videoListInfoWrapper {
    flex-direction: column;
  }
  .videoItem {
    gap: 0.5rem;
  }
.videoThumbnail {
  width: 50px;
  height: 95px;
}
.videoInfo p {
  /* line-height: 1; */
  min-height: 0px;
}

}