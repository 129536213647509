.copyLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.copyButton {
  background-color: #FF9800;
  color: white;
  padding: 10px 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyButton:hover {
  background-color: #E68900;
}

.copyMessage {
  margin-top: 5px;
  text-align: start;
  padding: 0 0 0 5px;
  font-size: 14px;
  color: green;
}
