.container {
    width: 100%;
  }
  
  .selectedRow {
    background-color: #f0f0f0;
  }
  
  .selectedRow:hover {
    background-color: #e0e0e0;
  }
  
  .searchInput {
    width: 80%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin: 3rem;
    gap: 10px;
  }