.cropWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.cropContainer {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background-color: transparent;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  border: black solid 3px;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  border-radius: 0 0 10px 10px;
  background-color: #000;
}

.zoomRange {
  padding: 0 10px;
  cursor: pointer;
  width: 100%;
}

.actionBtns {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.cancelButton,
.cropButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}

.cancelButton {
  background-color: #808080;
}

.cancelButton:hover {
  background-color: #808080bd;
}

.cropButton {
  background-color: #4caf50;
}

.cropButton:hover {
  background-color: #388e3c;
}
