.sharedVideoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.videoContainer {
    max-width: 400px;
    margin: auto;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 800px;
    /* min-height: 725px; */
    min-height: 720px;
}
.logo {
    position: absolute;
    top: 0%;
    padding: 16px;
    font-size: 1.5em;
    color: white;
    z-index: 10;
    padding-top: 10px;
    width: 100%;
    height: 107px;
    background: linear-gradient(165deg, #0090A5 6.28%, rgba(0, 55, 63, 0.00) 44.3%);
  }
  
.videoPlayer {
    width: 100%;
    position: relative;
}

video {
    width: 100%; 
    height: auto; 
    object-fit: contain; 
}
  
.videoInfo {
    padding:0 16px;
    color: #fff;
    position: absolute;
    z-index: 10;
    top: 85%;


}
  
.title {
    font-size: 16px;
    margin-top: -12%;

}
  
.likesCount {
    color: #fff;
    font-size: 12px;
    text-align: center;
}
  
.sideIcons {
    position: absolute;
    right: 10px;
    top: 65%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}
  
.icon {
    width: 24px;
    height: 24px;
    margin: 8px 0;
}

.likeIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
