.editBtn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-style: 1rem;
  background-color: #b0a544;
  color: white;
  margin-right: 5px;
}
.error {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

.editBtn:hover {
  background-color: #b38e30;
}

.deleteBtn {
  background-color: #d9534f;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-style: 1rem;
  color: white;
}

.deleteBtn:hover {
  background-color: #c9302c;
}