.success-registration {
  text-align: center;
}
.container-form {
    max-width: 370px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 30px auto;
  }
  
  .input-container {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    fill: #757575;
    width: 20px;
    height: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px 10px 10px 40px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    padding-left: 48px;
    padding-right: 55px;
  }
  
  .errmsg, .offscreen {
    color: red;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 0.75rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    margin-top: 5px;
  }
  
  .button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .link {
    color: #4caf50;
    text-align: center;
    display: block;
    margin-top: 15px;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .valid, .invalid {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .valid {
    color: limegreen;
  }
  
  .invalid {
    color: red;
  }
  .instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #757575;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: 5px;
  }
  
  .instructions > svg {
    margin-right: 0.25rem;
  }
  
  .offscreen {
    position: absolute;
    left: -9999px;
  }
  
  .hide {
    display: none;
  }
  
  .valid {
    color: limegreen;
    margin-left: 0.25rem;
  }
  
  .invalid {
    color: red;
    margin-left: 0.25rem;
  }
  
  .errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .line {
    display: inline-block;
  }

  .togglePasswordVisibilityIcon {
    right: 16px;
    cursor: pointer;
  }
  .togglePasswordVisibilityIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    right: 34px;
  }