/* HashtagList.module.css */

.hashtagListContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: start;
    width: 100%;
    margin-bottom: 20px;
    min-height: 70vh;
}

.topControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.searchContainer {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: white;
    padding: 0 5px;

}

.searchInput {
    margin-left: 8px;
    padding: 8px;
    /* border: 1px solid #ccc; */
    border: none;
    outline: none;
    background-color: white;

}
.inputField {
    margin-left: 8px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;

}

.boldHash {
    font-size: 26px;
}
.inputError {
    border-color: red;
  }

.hashtagButton, .addButton, .saveButton {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.hashtagButton {
    background-color: #f0f0f0;
}

.addButton {
    background-color: #4CAF50;
    color: white;
}

.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

.hashtagList {
    list-style-type: none;
    padding: 0;
}

.hashtagItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    gap: 5px;
}
.hashtagItem:hover {
    background-color: #eaeaea;
  }
.hashtagName {
    flex-grow: 1;
    word-wrap: break-word;
}

.buttonGroup button {
    margin-left: 10px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.buttonGroup .editButton {
    background-color: #b0a544;
    color: white;
}

.buttonGroup .deleteButton {
    background-color: #FF6347;
    color: white;
}

.saveButton {
    background-color: #4CAF50;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
.errorMessage {
    max-width: 205px;
    font-size: 12px;
}
@media screen and (max-width: 1024px) {
.hashtagListContainer {
    padding: 20px 0;
}
.hashtagItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.hashtagName {
    width: 100%;
}
}