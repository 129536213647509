.videoEditContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 550px;
    width: 100%;
    min-width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.videoEditContainer form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
}

.videoEditContainer textarea {
    width: 100%;
}

.videoEditContainer label {
    margin-bottom: 8px;
    font-weight: bold;
}

.videoEditContainerForm input, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.videoEditContainer textarea {
    resize: vertical;
    min-height: 100px;
}

.saveBtn{
    /* width: 100px; */
    padding: 8px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* font-size: 16px; */
    transition: background-color 0.3s;
    align-self: center;
}

.videoEditContainer button:hover {
    background-color: #147617;
}

.hashtagsContainer {
    /* margin-top: 20px; */
    margin-top: 35px;
    width: 100%;
}

.addHashtag {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
.addHashtag:hover {
    color: #0056b3;
}

@media screen and (max-width: 768px) {
    .videoEditContainer {
    min-width: 280px;
    }
}