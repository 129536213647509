.logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 150px; */
    /* height: 150px; */
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #e0e0e0;
    position: relative;
    margin: 0 auto;
    /* background-image: url('../../assets/partner_logo.svg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
  }
  
  .logoImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .addIconWrapper {
    position: absolute;
    bottom: 10px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .addIconWrapper:hover {
    transform: scale(1.1);
  }
  
  .deleteIconWrapper {
    position: absolute;
    top: 10px;
    right: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .deleteIconWrapper:hover {
    transform: scale(1.1);
  }
  
  .addIcon,
  .deleteIcon {
    width: 25px;
    height: 25px;
  }
  
  .errorMessage {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  .notificationMessage {
    font-size: 0.9rem;
    margin: 10px 0 0 0;
    text-align: center;
  }
  