.navbar {
    padding: 10px;
    /* margin: 1rem; */
  }
  
  .navbarList {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    margin: 0;
  }
  
  .navbarItem {
    margin: 0 20px;
  }
  
  .navLink {
    text-decoration: none;
    color: grey;
    font-size: 16px;
  }
  
  .navLink:hover {
    color: black;
  }
  
  .activeNavLink {
    /* font-weight: bold; */
    color: black;
  }