.hashtagSelectorContainerInput {
    /* width: 100%; */
    padding: 7px;
    /* margin-bottom: 20px; */
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 13px;
}

.hashtagList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.hashtagItem {
    border: 1px solid #ddd;
    padding: 3px 7px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 13px;
}

.hashtagItem:hover {
    background-color: #f0f0f0;
    color: #333;
}