/* .dataTable {
    text-align: center;
} */
.dataGrid {
    background-color: white;
    display: flex;
    /* max-width: 800px; */
    /* max-width: fit-content; */
    width: 100%;
    padding: 10px;
    margin: auto;
    /* text-decoration: none; */
}
.eye-icon {
   margin: auto;
   cursor: pointer;
}
