@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
  /* font-size: 22px; */
  font-size: 16px;
  color: #000; 
}

body {
  min-height: 100vh;
  background-color: #f4f4f4;
  width: 100%;
  font-style: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 1370px;
  width: 100%;
  padding:0 20px;
  margin: 0 auto;
}
/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: transparent;
  overflow: hidden;
  position: relative;
  height: 99.9vh;
  border-radius: 20px;
  overflow: scroll;
  width: 80%;
  max-width: 500px;
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  z-index: 2;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.vertical-video {
  object-fit: fill;
  max-width: 100%;
  max-height: 100vh;
}

.modal-content::-webkit-scrollbar {
  display: none;
} /*for IE, Edge and Firefox */

.modal-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* @media (min-width:1441px) {
  body {
    transform: scale(1.2);
    transform-origin: top left;
  }
  .container {
    min-width: 90vw;
    padding: 0 calc(20px*1.2);
  }
} */
@media (min-width: 1441px) {
  html {
    font-size: calc(16px + (100vw - 1441px) / 320);
  }

  body {
    font-size: calc(16px + (100vw - 1441px) / 320);
  }

  .container {
    width: calc(1370px + (100vw - 1441px) * 0.5);
    max-width: 90%;
    margin: 0 auto;
    padding:0 20px;
  }
}
@media (max-width: 480px) {
  * {
    letter-spacing: -0.5px;
  }
}
