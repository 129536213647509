.role-select {
    padding: 8px;
    border: none;
    background-color: #f1f1f1;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .role-select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .role-change-button {
    border: red 3px solid;
    color: red;
    padding: 6px 8px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .role-change-button:hover {
    background-color: red;
    color: white;
  }
  
  .active-button {
    color: red;
    background-color: white;
    border-color: red;
  }
  
  .role-change-button:disabled {
    /* background-color: #cccccc;
    cursor: not-allowed;
    color: #666;
    border-color: #cccccc; */
    display: none
  }
  