  .profileField {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .profileField input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .profileField svg {
    cursor: pointer;
  }

  .userForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 2rem 0;
    margin: 0 auto;
    min-width: 280px;
    max-width: 400px;
    
  }

  .inputGroup {
    display: flex;
    align-items: center;
    gap: 10px; 
    margin-bottom: 15px;
    flex-grow: 1;
    padding: 8px; 
    border-radius: 4px; 
    width: 100%;

    border: #e7e1e1 1px solid;
  }
  
  .inputGroup img {
    cursor: pointer; 
  }
  
  .inputGroup input {
    padding: 8px; 
    border: none;
    background-color: #f4f4f4;
    font-size: 1rem;
    width: 100%;
    font-family: 'Arial', sans-serif;
  }

  .inputGroup:focus-within {
    /* border: 2px solid #4CAF50; */
    border: 2px solid #808080ba;
    padding: 5px; 
    border-radius: 4px;
  }

  .inputGroup input:focus {
    outline: none;
    border-color: transparent;
  }



.errorText {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  color: red;
  padding: 0;
}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 3rem;
  padding: 30px 0;
}
  .submitButton {
    padding: 3px 20px; 
    background-color: #4CAF50; 
    color: white; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    font-size: 1rem; 
    transition: background-color 0.3s ease; 
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }
  
  .submitButton:active {
    transform: translateY(2px);
  }
  .cancelButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:0 3px;
    background-color: #ff9800; 
    color: white;
    border: none; 
    padding: 3px 20px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .cancelButton:hover {
    background-color: #fbc02d;
  }

  .notification {
    margin-top: 1rem;
  }

  .changePwdContainer {
    display: flex;
    justify-content: center;
  }
  
  .changePasswordButton {
    padding: 8px; 
    border: none;
    background-color: transparent;
    font-size: 1rem;
    align-self: flex-start;
    color: gray;
    cursor: pointer;
  }

  .changePasswordButton:hover {
    color: black;
  }

  .backIcon {
    width: 20px;
  }

  .inputGroup textarea {
    padding: 8px;
    border: none;
    background-color: #f4f4f4;
    font-size: 1rem;
    width: 100%;
    resize: none;
    margin: 0;
    font-family: 'Arial', sans-serif;
  }

  .inputGroup textarea:focus {
    outline: none;
    border-color: transparent;
  }
  .labelBio {
    text-align: left;
    width: 100%;
    margin: 0 0 0 5px;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
  }
  .inputGroup.textareaGroup img {
    align-self: flex-start;
    cursor: pointer;
    padding-top: 8px;
  }

  .autoExpand {
    width: 100%;
    resize: none;
    overflow-y: hidden;
    line-height: 24px;
    min-height: 48px;
    max-height: 120px;
    transition: height 0.2s ease;
  }