/* .container {
    padding: 20px;
  }  14 */
  .userDataTableContainer {
    max-width: 900px;
    width: 100%;
  }
  .info h1 {
    text-align: center;
  }
  .filter-section {
    margin-bottom: 20px;
  }
  
  .filter-label {
    margin-right: 10px;
  }
  
  .filter-input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .userlist-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .userlist-table th, .userlist-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
  }
  
  .userlist-table th {
    background-color: #f2f2f2;
  }
  
  .userlink {
    text-decoration: none;
    color: #007BFF;
  }
  
  .userlink:hover {
    color: #0056b3;
  }
  .search-container {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding-left: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    width: 300px;
    background-color: #fff;

  }
  
  .search-icon {
    margin-right: 5px;
  }
  
  .search-input {
    border: none;
    background: transparent;
    outline: none;
    padding: 5px;
    flex-grow: 1;
  }
  .paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
    padding: 20px 0;
  }

  .users-search-container {
    display: flex;
    align-items: center;
    margin: 15px 0;
    padding-left: 10px;
    max-width: 300px;
    min-widrh:150px;
    background-color: #fff;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 5px;

  }
  
  .users-search-icon {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .users-search-input {
    border: none;
    background: transparent;
    outline: none;
    padding: 10px 5px;
    width: 100%;
  }