.title {
    margin-bottom: 20px;
}
.discountForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
  }
  
  .formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    font-size: 16px;
    font-family: "Nunito", sans-serif;
  }
  
  .formGroup label {
    width: 185px;
    margin-right: 10px;
    padding: 10px;
    align-self: flex-start;
  }

  .partnerName {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
.formGroup input, .formGroup textarea{
    /* flex: 1; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    font-family: "Nunito", sans-serif;
  }
  .formGroup textarea{
    /* resize: vertical */
    width: 100%;
    height: auto;
    resize: none;
    overflow: hidden; 

  }
  .partnerSelect {
    /* flex: 1; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  .modalButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
  }
  
  .cancelButton,
  .saveButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 45px;
    font-size: 16px;
  }
  
  .cancelButton {
    background-color: #e0e0e0;
    color: black;
  }
  
  .saveButton {
    background-color: #4caf50;
    color: white;
  }
  
  .error {
    color: red;
    margin-bottom: 15px;
    text-align: center;
  }