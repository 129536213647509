.containerForm {
  max-width: 370px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 30px auto;
}

/* form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
} */

.inputContainer {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

.inputIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  fill: #757575;
  width: 20px;
  height: 20px;
}

/* input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  padding-left: 48px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
} */

.inputField {
  width: 100%;
  padding: 10px;
  padding-left: 48px;
  padding-right: 48px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.button {
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
}

.button:hover {
  background-color: #45a049;
}

p {
  font-size: 0.875rem;
  text-align: center;
  padding-top: 1rem;
}

p a {
  color: #4caf50;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}
.togglePasswordVisibilityIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.togglePasswordVisibilityIcon {
  right: 16px;
  cursor: pointer;
}
.authContainer {
  margin: 20px 0;
}