.successRegistration {
    text-align: center;
  }
  
  .container {
    width: 300px; 
    padding: 10px;
  } 
  
  .inputContainer {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .inputIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    fill: #757575;
    width: 20px;
    height: 20px;
  }
  
  .inputField {
    width: 100%;
    padding: 10px 65px 10px 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .errmsg, .offscreen {
    color: red;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 0.75rem;
    background: #757575;
    color: #fff;
    padding: 0.25rem;
    margin-top: 5px;
    border-radius: 0.5rem;
  }
  
  .button {
    background-color: #4caf50;
    color: white;
    padding: 8px 15px;
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  .button:hover {
    background-color: #45a049;
  }
  .button:disabled {
    background-color: gray;
  }
  
  .link {
    color: #4caf50;
    text-align: center;
    display: block;
    margin-top: 15px;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .valid, .invalid {
    position: absolute;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .valid {
    color: limegreen;
  }
  
  .invalid {
    color: red;
  }
  
  .togglePasswordVisibilityIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }