.EducationalContentContainer {
  padding: 20px;
  text-align: start;
  width: 100%;
  height: 100vh;
 }
.gradesList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  color: gray;
}
.gradesList li, .nestedList{
  list-style-type: none;
}


.gradeItem {
  min-width: 170px;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.gradeItem span{
  padding-right: 1rem;
}


.gradeItem > .nestedList {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 8px;

  

}


.gradeName {
  font-weight: bold;
  transition: color 0.3s;
  max-width: fit-content;

}

.gradeNameOpen {
  color: black;
  max-width: fit-content;
}

.itemsInnerContainer {
  width: 100%;
  /* display: inline-flex; */
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;
}

.ticketIcon {
  position: absolute;
  left: -29px;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
}

.icon {
  position: relative;
  width: 18px; /* Icon size */
  height: 18px; /* Icon size */
  display: inline-block;
  cursor: pointer;

}

/* Plus Icon */
.plus::before, .plus::after {
  content: '';
  position: absolute;
  background-color: rgba(157, 151, 151, 0.926);
  cursor: pointer;

}

.plus::before {
  top: 50%;
  left: 8px; 
  width: 2px; 
  height: 18px;
  cursor: pointer;
  transform: translateY(-50%);

}

.plus::after {
  top: 8px; 
  left: 50%;
  width: 18px; 
  height: 2px; 
  cursor: pointer;
  transform: translateX(-50%);

}

/* Delete (X) Icon */
.delete::before, .delete::after {
  content: '';
  position: absolute;
  background-color: rgba(157, 151, 151, 0.926);
  cursor: pointer;


}

.delete::before, .delete::after {
  top: 50%;
  left: 50%;
  width: 18px;
  height: 2px;
  cursor: pointer;
  transform: translate(-50%, -50%) rotate(45deg);
}

.delete::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.icon:hover::before, .icon:hover::after {
  background-color: black;
  cursor: pointer;
}





.nestedList {
  padding-left: 30px;
  display: none;
}


/*dragAndDrop start*/
.nestedList li span{
  cursor: grab;
  line-height: 1.8;

}

.highlight {
  background-color: rgba(223, 216, 216, 0.993);
}


.gradeItem {
  transition: 0.2s ease-in-out; 
}

.dropLastArea{
  content: ''; 
  display: block; 
  height: 20px;
  padding: 0;
  margin: 0;
}

.beforeDrop::before {
  content: ''; 
  display: block; 
  border-top: 2px dashed #ccc; 
  background-color: rgba(240, 240, 240, 0.5);
  box-sizing: border-box;
}

.beforeDrop {
  width: 100%
}

.dropIn {
  width: 100%;
  border: 2px dashed #ccc; 
  background-color: rgba(240, 240, 240, 0.5);
  box-sizing: border-box;
  padding-left: 0.5rem;
}

/*dragAndDrop end*/

.nestedListOpen {
  display: block;
}

.listInput {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 16px);
}

.formSaveConfirmBtn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}
.categoryBtn {
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.formSaveConfirmBtn:hover {
  background-color: #147617;;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  z-index: 1001;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;
}
.listInput {
  display: block;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px);
    width: 220px;
}


/*checkbox start*/

.topicCheckbox {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.5px solid #808080;
  border-radius: 3px;
  margin-right: 5px;
  vertical-align: sub;
  
}
.checked {
  display: inline-block;
  content: '';
  width: 1rem;
  height: 1rem;
  background-image: url("../assets/check_icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

/*checkbox end*/


