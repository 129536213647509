.caregoryContent {
    display: flex;
    align-items: center;
    gap: 5px;
}
.categoryImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
/* @media screen and (max-width: 768px) {
.caregoryContent {
    width: 100%;
}
    .categoryName {
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
    }
} */