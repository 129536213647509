.editBtn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-style: 1rem;
    background-color: #b0a544;
    color: white;
}
.editBtn:hover {
    background-color: #b38e30;
  }
.error {
    color: red;
    margin-bottom: 15px;
    text-align: center;
  }