.formTitle {
    margin: 20px auto;
    font-weight: 400;
    text-align: center;
}
.deleteIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .deleteIcon::before,
  .deleteIcon::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: gray;
  }
  
  .deleteIcon::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .deleteIcon::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  



  