.hashtagList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.hashtagItem {
    border: 1px solid #ddd;
    padding: 5px 7px;
    border-radius: 4px;
    display: flex;
    /* align-items: center; */
    gap: 3px;
    font-size: 13px;
    font-weight: bold;
    
    word-break: break-word;
    background: #8080804f;

}
.hashtagItem:hover {
    background-color: #f0f0f0;
    color: #333;
}

.hashtagDelete {
    cursor: pointer;
    color: red;
    font-weight: bold;
    padding: 0px 0px 0 3px;
    background-repeat: 4px;
}
