.lessonContainer {
    margin: 105px 10% 0;
    padding-bottom: 30px;
  }

.quizForm {
    background: #fff;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .questionBlock {
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .questionInput, .answerInput {
    width: calc(100% - 30px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    overflow: hidden;
    resize: none; 
    min-height: 38px;
    box-sizing: border-box; 
    font-family: inherit;
  }
  
  .questionInput {
    width: 100%;
  }


  .answerLabel {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .radioInput {
    appearance: none;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  .radioInput:checked {
    border-color: #4caf50;
  }
  
  .radioInput:checked::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #4caf50;
  }
  .buttonGroup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .saveButton, .cancelButton {
    padding: 10px 21px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 10px;

  }
  .cancelButton {
    background-color: #f44336;
    color: white;
  }

  .createLessonButton {
    outline: none;
    background-color: transparent;
    color: #0064BC;
  }
  .createLessonButton:hover {
    background-color: transparent;
    color:#16038b
  }

  .openQuizButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: transparent;
    border: none;
    color: black;
    padding: 0;
    font-size: 1rem;
  }
  .openQuizButton:hover {
    background-color: transparent;
  }
  .openQuizButton img{
    cursor: pointer;
  }

.questionContainer {
  margin-bottom: 20px;
  line-height: 2;
}

.questionContainer h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.answer {
  padding-left: 20px;
  position: relative;
  margin-bottom: 5px;
}

.answer:before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.correctAnswer {
  color: green;
}

.correctAnswer:before {
  background-color: green;
}
.deleteQuizBtn {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}
.deleteQuizBtn:hover {
  background-color: transparent;
}
.testBtn {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.available {
  color: #2B992A;
}
.unavailable {
  color: #FF573D;
}
.lessonInfoText {
  text-align: start;
  margin-top: 1rem;
}
.lessonInfoText p{
  text-align: start;
  font-size: 1rem;
}
.rewardGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.invalidInput {
  border-color: red;
}

.formErrorMessage {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0;
}

.errorMessageContainer {
  margin-bottom: 1rem;
}
.responseMessage {
  padding: 0 10px;
  text-align: start;
 }