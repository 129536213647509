.video-upload-wrapper {
    display: flex;
    /* width: 90%; */
    justify-content: center;
    padding: 30px 0;
  }
  
  .video-upload-container {
    width: 100%;
    max-width: 600px; /* Adjust width as needed */
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Simple shadow for styling */
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .upload-title {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    
  }
  
  .form-label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .upload-input,
  .upload-input.description {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-sizing: border-box; /* Makes sure padding doesn't affect width */
  }
  
  .upload-input.description {
    height: 100px; /* Larger textarea for descriptions */
    resize: vertical; /* Allow vertical resizing */
  }
  
  .file-upload-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative; /* Position relative to allow absolute positioning inside */
    justify-content: flex-end;

    gap:1rem;
    flex-wrap: wrap;

  }
  
  .file-upload-button {
    background-color: #ccc; /* Gray color */
    /* color: #333;  */
    color: black; 
    padding: 10px 20px;
    /* margin-right: 10px; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px; /* Match the font size with the upload button */
    text-align: center;
    width: 180px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .file-upload-button:hover {
    background-color: #bbb; /* Slightly darker on hover */
  }
  
  .upload-button {
    padding: 12px 20px;
    border: none;
    border-radius: 20px;
    background-color: #75e879; /* Example button color */
    color: black;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* flex-grow: 1; */
    width: 180px;
  }
  
  .upload-button:hover {
    background-color: #38e13e; /* Darken button on hover */
  }
  
  .upload-progress {
    width: 100%;
    height: 20px;
    margin-bottom: 15px;
  }
  
  .upload-notification {
    text-align: center;
    margin-top: 15px;
    color: #28a745; /* Success color */
  }
  
  .file-name {
    position: absolute;
    left: 0;
    right: 0;
    padding: 10px 20px;
    margin-left: 130px;
    color: #555;
    font-size: 14px;
  }  
  .cancel-video-button {
    padding: 12px 20px;
    border: none;
    border-radius: 20px;
    background-color: #ff6347;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 180px;
  }
  
  .cancel-video-button:hover {
    background-color: #e63939;
  }
  .cancel-notification {
    text-align: center;
    margin-top: 15px;
    color: #black;
  }
  
  .hashtags-section {
    margin: 1rem 0 2rem 0;
    width: 100%;
}
  
  .hashtag-notification {
    text-align: center;
    margin-top: 15px;
    color: #black;
  }